import * as React from "react"

import Layout from "../components/Layout"
import SeoHelmet from "../components/SeoHelmet"

const NotFoundPage = () => (
  <Layout spa={false}>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export const Head = () => <SeoHelmet title="404: Not Found" />

export default NotFoundPage
